<template>
  <vp-base-layout
    page-title="기기정보 입력"
    page-default-back-link="/">
    <p>접수하시려는 휴대폰의 모델정보를 입력해주세요.</p>
    <div class="search">
      <ion-accordion-group
        ref="accordionGroup">
        <ion-accordion value="first">
          <ion-item
            slot="header"
            color="light">
            <ion-label>1. 제조사 선택</ion-label>
          </ion-item>
          <div
            class="ion-padding"
            slot="content">
            <ion-list v-if="manufacturList.length > 0">
              <ion-radio-group
                v-model="data.manufactur"
                class="radio-list vertial"
                @ion-change="handleFactur($event)">
                <ion-item
                  v-for="(item, index) in manufacturList"
                  :key="index">
                  <template
                    v-if="item.brand_nm !== undefined && item.brand_nm !== ''">
                    <ion-label>
                      <span class="radio-txt">{{ item.brand_nm }}</span>
                    </ion-label>
                    <ion-radio :value="item.brand_nm" />
                  </template>
                  <template v-else>
                    <p>제조사 목록이 없습니다.</p>
                  </template>
                </ion-item>
              </ion-radio-group>
            </ion-list>
            <ion-list v-else>
              <p>제조사 목록이 없습니다.</p>
            </ion-list>
          </div>
        </ion-accordion>
        <ion-accordion
          value="second"
          :disabled="!data.manufactur">
          <ion-item
            slot="header"
            color="light">
            <ion-label>2. 시리즈 선택</ion-label>
          </ion-item>
          <div
            v-if="seriesList.length > 0"
            class="ion-padding"
            slot="content">
            <ion-list v-if="seriesList.length > 0">
              <ion-radio-group
                v-model="data.series"
                class="radio-list vertial"
                @ion-change="handleSeries($event)">
                <ion-item
                  v-for="(item, index) in seriesList"
                  :key="index">
                  <template
                    v-if="item.series_md !== undefined && item.series_md !== ''">
                    <ion-label>
                      <span class="radio-txt">{{ item.series_md }}</span>
                    </ion-label>
                    <ion-radio :value="item.series_md" />
                  </template>
                  <template v-else>
                    <p>시리즈 목록이 없습니다.</p>
                  </template>
                </ion-item>
              </ion-radio-group>
            </ion-list>
            <ion-list v-else>
              <p>시리즈 목록이 없습니다.</p>
            </ion-list>
          </div>
        </ion-accordion>
        <ion-accordion
          value="third"
          :disabled="!data.series">
          <ion-item
            slot="header"
            color="light">
            <ion-label>3. 모델명 선택</ion-label>
          </ion-item>
          <div
            v-if="modelList.length > 0"
            class="ion-padding"
            slot="content">
            <ion-list v-if="modelList.length > 0">
              <ion-radio-group
                v-model="data.modelName"
                class="radio-list vertial"
                @ion-change="handleModel($event)">
                <ion-item
                  v-for="(item, index) in modelList"
                  :key="index">
                  <template v-if="item.pet_nm_model !== undefined && item.pet_nm_model !== ''">
                    <ion-label>
                      <span class="radio-txt">{{ item.pet_nm_model }}</span>
                    </ion-label>
                    <ion-radio :value="item.pet_nm_model" />
                  </template>
                  <template v-else>
                    <p>모델 목록이 없습니다.</p>
                  </template>
                </ion-item>
              </ion-radio-group>
            </ion-list>
            <ion-list v-else>
              <p>모델 목록이 없습니다.</p>
            </ion-list>
          </div>
        </ion-accordion>
        <ion-accordion
          value="fourth"
          :disabled="!data.modelName">
          <ion-item
            slot="header"
            color="light">
            <ion-label>4. 용량/세부모델 선택</ion-label>
          </ion-item>
          <div
            v-if="volumnList.length > 0"
            class="ion-padding"
            slot="content">
            <ion-list v-if="volumnList.length > 0">
              <ion-radio-group
                v-model="data.volumn"
                class="radio-list vertial"
                @ion-change="handleVolumn($event)">
                <ion-item
                  v-for="(item, index) in volumnList"
                  :key="index">
                  <template
                    v-if="item.ram_md !== undefined && item.ram_md !== ''">
                    <ion-label>
                      <span class="radio-txt">{{ item.ram_md }}</span>
                    </ion-label>
                    <ion-radio :value="item.ram_md" />
                  </template>
                  <template v-else>
                    <p>용량 목록이 없습니다.</p>
                  </template>
                </ion-item>
              </ion-radio-group>
            </ion-list>
            <ion-list v-else>
              <p>용량 목록이 없습니다.</p>
            </ion-list>
          </div>
        </ion-accordion>
      </ion-accordion-group>
    </div>          
    
    <vp-button
      expand="full"
      :types="[{'fixed': fixed}]"
      :disabled="!(data.manufactur !== undefined && data.series !== undefined && data.modelName !== undefined)"
      @click="deviceInputStore">
      기기정보 입력하기
    </vp-button>
  </vp-base-layout>
</template>
  
<script setup>
//---------------------------------------------------------------------------------------------------------------------
// import
//---------------------------------------------------------------------------------------------------------------------
import { 
  IonAccordion, IonAccordionGroup, IonItem, IonLabel,
  IonButton, IonRadio,
  IonRadioGroup, IonList,
  toastController
} from '@ionic/vue'
import { useStore } from 'vuex'
import { ref, onMounted } from 'vue'
import { useIonRouter } from '@ionic/vue'
import { listData } from '@/api/dealer/Receipt.js'
//---------------------------------------------------------------------------------------------------------------------
// 변수 선언 && 함수 선언
//---------------------------------------------------------------------------------------------------------------------
const store = useStore()
const ionRouter = useIonRouter()

const accordionGroup = ref(null)
const fixed = ref(true)
const data = ref({
  manufactur: undefined,
  series: undefined,
  modelName: undefined,
  volumn: undefined
})
const manufacturList = ref([])
const seriesList = ref([])
const modelList = ref([])
const volumnList = ref([])
const allList = ref([])
const prdtId = ref([])

const pageInfo = ref({
  total: 0,
  pageNum: 1,
  pageSize: 99999,
  ordering: 'pet_nm-'
})

// 상품id 중 가장 작은거
function getMin(obj){
  let len = obj.length, min = Infinity
  while (len--) {
    if (obj[len].id < min) {
      min = obj[len].id
    }
  }
  return min
}

//기기 조회
const fetchList = async () => {
  const response = await listData(pageInfo.value)
  allList.value = response.data.results
   
  
  let sortRes = response.data.results.reduce(
    (ac, v) => (ac.find((x) => x.brand_nm === v.brand_nm) ?  ac : [...ac, v]),[]
  )

  sortRes = sortRes.filter((x) => x.brand_nm === 'LG전자').concat(sortRes.filter((x) => x.brand_nm !== 'LG전자'))
  sortRes = sortRes.filter((x) => x.brand_nm === '애플').concat(sortRes.filter((x) => x.brand_nm !== '애플'))
  manufacturList.value = sortRes.filter((x) => x.brand_nm === '삼성전자').concat(sortRes.filter((x) => x.brand_nm !== '삼성전자'))
}

//제조사 선택
const handleFactur = (event) => {
  data.value.manufactur = event.detail.value
  store.dispatch('Receipt/setManuFactur', data.value.manufactur)
  seriesList.value = allList.value.filter((v,i) => v.brand_nm === data.value.manufactur).reduce(
    (ac, v) => (ac.find((x) => x.series_md === v.series_md) ?  ac : [...ac, v]),[]
  )

  seriesList.value = seriesList.value.reduce((ac, v) => ac.includes(v) ? ac : [...ac, v], [])

  
  const nativeEl = accordionGroup.value.$el
  nativeEl.value = 'second'

  data.value.series = undefined
  data.value.modelName = undefined
  data.value.volumn = undefined
}
//시리즈 선택
const handleSeries = (event) => {
  data.value.series = event.detail.value
  store.dispatch('Receipt/setSeries', data.value.series)

  modelList.value = allList.value.filter((v) => v.brand_nm === data.value.manufactur && v.series_md === data.value.series).reduce(
    (ac, v) => (ac.find((x) => x.pet_nm === v.pet_nm) ?  ac : [...ac, v]),[]
  )
  

  //시리즈 선택 - pet_nm 으로 필터 
  // 맨 뒤가 T,G,B일때 용량을 제거 후, 시리즈만 표기한다.
  modelList.value.forEach( v => {
  const modelLast = v.pet_nm.slice(-1)
    if(modelLast === 'T' || modelLast === 'G' || modelLast === 'B'){
      const modelArr = v.pet_nm.split(' ')
      //volumnList.value = [{ram_md: modelArr[modelArr.length - 1]}]
      volumnList.value.push({model_md: modelArr.slice(0,-1).join(' '), ram_md: modelArr[modelArr.length - 1]})
      v.pet_nm_model = modelArr.slice(0,-1).join(' ')
    }  else {
      v.pet_nm_model = v.pet_nm
    }
  })

  modelList.value = modelList.value.filter((obj, idx) => {
    const isFirstFindIdx = modelList.value.findIndex((obj2) => obj2.pet_nm_model === obj.pet_nm_model)
    return isFirstFindIdx === idx
  })

  
  const nativeEl = accordionGroup.value.$el 
  nativeEl.value = 'third'
  
  data.value.modelName = undefined
  data.value.volumn = undefined
}
//모델명 선택
const handleModel = (event) => {
  data.value.modelName = event.detail.value
  store.dispatch('Receipt/setModelName', data.value.modelName)

  const model = data.value.modelName
  volumnList.value = volumnList.value.filter( (v,i)=> v.model_md == model )

  
  const nativeEl = accordionGroup.value.$el
  nativeEl.value = 'fourth'
  data.value.volumn = undefined
  store.dispatch('Receipt/setVolumn', undefined)
}
//용량/세부모델 선택
const handleVolumn = (event) => {
  data.value.volumn = event.detail.value
  store.dispatch('Receipt/setVolumn', data.value.volumn)
}

const handleCheck = async() => {
  const toast = await toastController.create({
    message: '기기정보를 모두 선택해 주세요.',
    buttons: ['확인']
  })
  await toast.present()
}
const handleProductCheck = async() => {
  const toast = await toastController.create({
    message: '선택하신 기기의 정보가 없습니다. 다른 제품을 선택해 주세요',
    buttons: ['확인']
  })
  await toast.present()
}

//기기정보 입력하기
const deviceInputStore = () => {
  if(data.value.manufactur === undefined || data.value.series === undefined
  || data.value.modelName === undefined){
    handleCheck()
    return
  }

  console.log(data.value.series)
  console.log(data.value.modelName)
  console.log(data.value.volumn)
 
  if(data.value.volumn !== undefined){
    prdtId.value = allList.value.filter((v) => {v.brand_nm === data.value.manufactur
    && v.series_md === data.value.series && v.pet_nm === data.value.modelName + ' ' +data.value.volumn 
    // && v.ram_md === data.value.volumn
    
      if( v.pet_nm === data.value.modelName + ' ' +data.value.volumn) {
      console.log(v.series_md)
      console.log(v.pet_nm)
      console.log(v.ram_md)
        return v
      }
    })
  } else {
    prdtId.value = allList.value.filter((v) => v.brand_nm === data.value.manufactur
    && v.series_md === data.value.series && v.pet_nm === data.value.modelName)
  } 
  
  console.log(prdtId.value)
  if(prdtId.value.length > 0){
    store.dispatch('Receipt/setProduct', getMin(prdtId.value))
    ionRouter.push({name: 'inspectGrade'})
  } else {
    handleProductCheck()
    return
  }
}

onMounted(() => {
  store.dispatch('Receipt/setManuFactur', undefined)
  store.dispatch('Receipt/setSeries', undefined)
  store.dispatch('Receipt/setModelName', undefined)
  store.dispatch('Receipt/setVolumn', undefined)
  fetchList()
})
//---------------------------------------------------------------------------------------------------------------------
// life Cycle
//---------------------------------------------------------------------------------------------------------------------
</script>
  